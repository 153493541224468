import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../authentication/auth-service.service';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CompetionResultService } from './competion-result.service'
import { Iuser } from '../model/Iuser';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';
import { IVoteContainer } from '../model/IVoteContainer';
import { UserServiceService } from '../users/user-service.service';
import { ResultModalComponent } from './result-modal/result-modal.component';
import { IComp, ISub, IVote, IVoter } from './competition-result-types';
import { isPast } from 'date-fns';

@Component({
    selector: 'app-competition-result',
    templateUrl: './competition-result.component.html',
    styleUrls: ['./competition-result.component.scss']
})
export class CompetitionResultComponent implements OnInit {
    public competitions: IComp[] = [];
    private users: Iuser[] = [];
    public user: Iuser | undefined = undefined;
    //private voters: IVoter[] = [];

    constructor(private zone: NgZone, private competitionService: CompetionResultService, private userService: UserServiceService, private modalService: NgbModal, protected authService: AuthService, private router: Router) {
        if (!this.authService.loggedIn) {
            this.router.navigate(['/home']);
        }
    }

    async ngOnInit(): Promise<void> {
        this.authService.loggedIn.subscribe((l: boolean) => {
            if (!l) {
                this.router.navigate(['/home']);
            }
        });

        const t: any = await this.authService.getCurrentUser$().toPromise();
        const u = JSON.parse(t.aud);
        this

        await this.userService.fetchFilteredUsers().subscribe((users: Iuser[]) => {
            this.users = users;
            this.user = users.find(f => u.iduser === f.iduser);
            //this.competitionService.fetchCompetitionImageVote()
        });

        this.competitionService.fetchCompetitions().subscribe((competitions: Icompetition[]) => {
            this.competitions = [];
            competitions.sort((a: Icompetition, b: Icompetition) => a.Id > b.Id ? -1 : 1);
            competitions.map((competition: Icompetition) => {
                this.readCompetition(u, competition);
            })
        });
    }

    readCompetition(u: any, competition: Icompetition) {
        const mc: IComp = {
            comp: competition,
            themes: []
        };
        this.competitions.push(mc);
        this.competitionService.fetchSubCompetitions(competition.Id).subscribe((subCompetitions: Isubcompetition[]) => {
            subCompetitions.map((subCompetition: Isubcompetition) => {
                this.readSub(u, mc, subCompetition);
            })
        })

    }

    async readSub(u: any, competition: IComp, subCompetition: Isubcompetition) {
        const iSub: ISub = {
            theme: subCompetition,
            images: [],
            voters: [],
        };
        competition.themes ?.push(iSub);
        console.log(u);

        await this.competitionService.fetchCompetitionImages(iSub.theme).subscribe(async (compImages): Promise<ICompetitionImage[]> => {
            compImages.filter(f => {
                if (isPast(new Date(competition.comp.EndoParticipation))) {
                    return true;
                } else {
                    if (u.globaladmin) {
                        return true;
                    } else {
                        return u.iduser === f.UserId
                    }
                }
            }
            ).map((compImage: ICompetitionImage) => {
                this.readComments(u, competition, iSub, compImage);
            });

            await this.users.map(async (u): Promise<Iuser> => {
                await this.competitionService.fetchCompetitionImageVote(subCompetition.CompetitionId, iSub.theme.Id, u.iduser).subscribe(async (v): Promise<ICompetitionImageVote[]> => {
                    const voter: IVoter = {
                        user: u,
                        votes: v.filter((f: ICompetitionImageVote) => f.VoteValue > 0)
                    }

                    voter.votes.map((voterVote: ICompetitionImageVote) => {
                        const iVote: IVote | undefined = iSub.images.find((f: IVote) => f.ImageId === voterVote.ImageId);
                        if (isPast(new Date(competition.comp.EndOfVoting))) {
                            console.log('true');
                        } else {
                            if (u.globaladmin) {
                                console.log('true');
                            } else {
                                console.log('true');
                                //return u.iduser === f.UserId
                            }
                        }
                        if (iVote) {
                            iVote.AddVote(voterVote.VoteValue);
                        }
                    });

                    if (voter.votes.length > 0) {
                        iSub.voters ?.push(voter);
                    }
                    return v;
                })

                return u;
            })

            setTimeout(() => {
                iSub.images.sort((a: IVote, b: IVote) => {
                    return a.compare(b);
                })

                let i = 1;
                let last: IVote | undefined = undefined;
                iSub.images.map((m: IVote) => {
                    if (!last || last.compare(m) != 0) {
                        m.Position = i;
                    } else {
                        m.Position = last ?.Position;
                    }
                    last = m;
                    i++;
                })
            }, 5000);
            return compImages;
        })
    }

    readComments(u: any, competition: IComp, sub: ISub, image: ICompetitionImage) {
        const vote: IVote = new IVote(image);

        this.competitionService.fetchCompetitionImageComments(sub.theme.CompetitionId, sub.theme.Id, image.ImageId).subscribe((comment: ICompetitionImageComment[]) => {
            vote.Comments = comment.filter(f => {
                if (isPast(new Date(competition.comp.EndOfVoting))) {
                    return true;
                } else {
                    if (u.globaladmin) {
                        return true;
                    } else {
                        return u.iduser === f.UserId
                    }
                }
            });

        })

        sub.images ?.push(vote);
    }

    onPdfClick(competition: Icompetition, ppi?: number, unSharp?: boolean) {
        this.competitionService.downloadPDF(competition.Id, ppi ? ppi : 96, unSharp ? unSharp : false);/*.subscribe((res: any) => {
      const fileURL = res;//URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });*/
    }

    onWordClick(competition: Icompetition) {
        this.competitionService.downloadWord(competition.Id);
        /*.subscribe((res: any) => {
          const fileURL = res;//URL.createObjectURL(res);
          window.open(fileURL, '_blank');
        });*/
    }
}
