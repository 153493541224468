import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from "rxjs/operators";
import { config } from '../config/config';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';
import { timeout } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompetionResultService {

    constructor(private http: HttpClient) { }

    fetchCompetitions() {
        return this.http.get<any>(`${config['apiUrl']}/competition/fetchCompetitionResult`);
    }

    fetchSubCompetitions(Id: number) {
        return this.http.get<any>(`${config['apiUrl']}/competition/fetchSubCompetition/` + Id, );
    }

    fetchCompetitionImages(sub: Isubcompetition) {
        return this.http.get<ICompetitionImage[]>(`${config['apiUrl']}/competition/fetchCompetitionImages/` + sub.CompetitionId + '/' + sub.Id);
    }

    fetchCompetitionImageComments(CompetitionId: number, SubId: number, ImageId: number) {
        return this.http.post<ICompetitionImageComment[]>(`${config['apiUrl']}/competition/fetchCompetitionResultImageComments`, { CompetitionId: CompetitionId, SubId: SubId, ImageId: ImageId });
    }

    fetchCompetitionImageVote(CompetitionId: number, SubId: number, UserId: number) {
        return this.http.post<ICompetitionImageVote[]>(`${config['apiUrl']}/competition/fetchCompetitionResultImageVotes`, { CompetitionId: CompetitionId, SubId: SubId, UserId: UserId });
    }

    downloadPDF(Id: number, ppi: number, unSharp: boolean): any {
        return this.http.get(`${config['apiUrl']}/competition/resultToPdf/` + Id + '?ppi=' + ppi + '&unSharp=' + unSharp + '&debug=false', {
            responseType: 'arraybuffer',
        }).pipe(timeout(300000)).subscribe(
            (data: any) => {
                const downloadedBlob = new Blob([data], { type: 'application/pdf' });
                const blobUrl = window.URL.createObjectURL(downloadedBlob);
                window.open(blobUrl);
            });
    }

    downloadWord(Id: number): any {
        return this.http.get(`${config['apiUrl']}/competition/resultToWord/` + Id, {//} + '?debug=true', {
            responseType: 'arraybuffer',
        }).pipe(timeout(500000)).subscribe((data: any) => {
            const downloadedBlob = new Blob([data], { type: 'application/word' });
            const blobUrl = window.URL.createObjectURL(downloadedBlob);
            window.open(blobUrl);
        });
    }

    fetchCompetitionImageVotes(CompetitionId: number, SubId: number) {
        return this.http.post<ICompetitionImageVote[]>(`${config['apiUrl']}/competition/fetchCompetitionResultImageVotes`, { CompetitionId: CompetitionId, SubId: SubId });
    }
}
