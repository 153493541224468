<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">

                <div class="col-5"><strong>Tävlingsnamn</strong>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="card" *ngFor="let competition of competitions">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">{{competition.comp.CompetitionName}}</div>
                        <div class="col-2">
                                            <Button
                                                (click)="onPdfClick(competition.comp, competition.ppi, competition.unSharp)" class="btn btn-primary position-relative"
                                            >
                                                <fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>
                                            </Button>
                                            &nbsp;
                                            <!--Button
                                                (click)="onWordClick(competition.comp)" class="btn btn-primary position-relative"
                                            >
                                                <fa-icon [icon]="['fas', 'file-word']"></fa-icon>
                                            </Button-->
                        </div>
                                        <div class="col-2">
                                            <label>Pixlar per tum</label><br>
                                            <input [(ngModel)]="competition.ppi" type="number" style="width:95%">
                                        </div>
                                        <div class="col-2">
                                            <label>Oskarp mask</label><br>
                                            <input [(ngModel)]="competition.unSharp" type="checkbox">
                                        </div>
                    </div>
                </div>
                <div class="card-body">
                   <app-sub-competition [subCompetitions]=competition.themes [competition]="competition" [user]=user></app-sub-competition>
                </div>
            </div>
        </div>
    </div>
</div>
